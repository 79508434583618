import { CMSDialog, CMSNotification, getI18n, useDialogStore, useNotificationStore } from '@editor/ui-utils';
import { DialogChainObject } from 'quasar';

const { showDialog } = useDialogStore();
const { showNotification } = useNotificationStore();
let dialog: DialogChainObject | undefined;

const onOnline = (): void => {
  if (dialog) {
    dialog.hide();
    showNotification(new CMSNotification(getI18n().t('pingApi.backOnline'), 'positive'));
  }
};
const onOffline = (): void => {
  dialog = showDialog(
    new CMSDialog({
      title: getI18n().t('pingApi.offline'),
      noBackdropDismiss: true,
      noEscDismiss: true,
      ok: false,
    }),
  );
};
const clear = (): void => {
  window.removeEventListener('online', onOnline);
  window.removeEventListener('offline', onOffline);
};
export const useOnlineCheck = (): { clear(): void } => {
  clear();
  window.addEventListener('online', onOnline);
  window.addEventListener('offline', onOffline);
  if (!navigator.onLine) {
    onOffline();
  }
  return {
    clear,
  };
};
