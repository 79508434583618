<template>
  <div class="login-layout">
    <div class="relative">
      <svg class="absolute login-bg-top" height="130" viewBox="0 0 166 130" width="166">
        <path d="M36.305 0h56v56h-56z" fill="#00A19A" />
        <path d="M0 22h36.305v34H0z" fill="#00636C" />
        <path d="M92 28h29.898v28H92z" fill="#F39200" />
        <path d="M92 56h74v74H92z" fill="#95C11F" />
      </svg>
      <svg class="absolute login-bg-bottom" height="125" viewBox="0 0 125 125" width="125">
        <path d="M58 58h67v67H58z" fill="#DEDC00" />
        <path d="M29 58h29v29H29z" fill="#F39200" />
        <path d="M0 0h58v58H0z" fill="#00964F" />
      </svg>
      <q-card class="login-card">
        <q-card-section class="flex-center column">
          <img alt="Burda Forward" :src="bfLogo" class="q-mb-xl" />
          <q-btn :href="LOGIN_URL" color="primary" @click.prevent="redirectToSSO">Login with Burda Forward account</q-btn>
          <q-banner v-if="!userStore.isEnabled && userStore.loggedIn" rounded class="q-mt-lg bg-red-2 text-red-8">
            Your account is disabled, please contact your superior to get access to the editor.
          </q-banner>
        </q-card-section>
      </q-card>
    </div>
    <div class="login-lang">
      <language-selector />
    </div>
  </div>
</template>

<script lang="ts" setup>
/* global GLOBAL_CONFIG */
import { LanguageSelector } from '@editor/ui-utils';
import { useUserStore } from '@editor/ui-utils';
import { QBanner, QBtn, QCard, QCardSection } from 'quasar';
import { onMounted, ref } from 'vue';

const userStore = useUserStore();
const LAST_URL_NAME = 'BF_LAST_VISITED_URL';

/*
Check if redirect_uri is set in the URL param and use it.
It is set when requesting swagger page, but login is not done yet.
example:
https://sandbox-vibe.burda-forward.de/articles?redirect_uri=https://api.sandbox-vibe.burda-forward.de/docs/
*/
const urlParams = new URLSearchParams(location.search);
const redirectUriString: string = urlParams.get('redirect_uri') || location.href;

/*
Once we are returning from AWS Cognito, a query parameter code is added to the URL.
We save it and we hide from the URL.
 */
const redirectUriNoCode = new URL(redirectUriString);
const authenticationCode = redirectUriNoCode.searchParams.get('code');
redirectUriNoCode.searchParams.delete('code');

// always use https://sandbox-vibe.burda-forward.de/ as the redirect_uri
const ssoUri = `${GLOBAL_CONFIG.SSO_REDIRECT}&redirect_uri=${location.origin}/`;

const LOGIN_URL = ref(ssoUri);

import bfLogo from '@/assets/logo-bf.png';

const redirectToSSO = () => {
  // Save the redirect URL in the session storage, so we can restore the specific page after the login
  // e.g. https://sandbox-vibe.burda-forward.de/articles/cfac8a52-bc0a-4e20-a73b-6fd6bb92b09e?tab=basics
  sessionStorage.setItem(LAST_URL_NAME, redirectUriNoCode.href);
  location.href = LOGIN_URL.value;
};

onMounted(async () => {
  LOGIN_URL.value = ssoUri;
  if (!userStore.loggedIn) {
    // Check if we are redirected from login
    if (authenticationCode) {
      // call /oauth2/token endpoint to check the authentication code and redirect the user to the last visited page
      await userStore.authenticate(authenticationCode);
      const lastUrl = sessionStorage.getItem(LAST_URL_NAME);
      if (lastUrl) {
        sessionStorage.removeItem(LAST_URL_NAME);
      }
      location.replace(lastUrl || redirectUriNoCode.href);
    }
  }
});
</script>

<style lang="scss" scoped>
.login-layout {
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.login-card {
  width: 430px;
  min-height: 180px;
  padding: 16px;
  max-width: 100%;
  position: relative;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.25);
  border-radius: 0;
}

.login-bg-bottom {
  bottom: -67px;
  left: -58px;
}

.login-bg-top {
  top: -56px;
  right: -74px;
}

.login-lang {
  position: fixed;
  bottom: 1.25rem;
  right: 2rem;

  :deep(.q-btn) {
    color: var(--v-secondary-lighten3);
    text-transform: none;
    text-indent: initial;
    letter-spacing: initial;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
