window.GLOBAL_CONFIG = {
  API_HOST: import.meta.env.VITE_APP_API_BASE_URL,
  SSO_REDIRECT: import.meta.env.VITE_APP_SSO_REDIRECT,
  URL_BUNTE: import.meta.env.VITE_APP_URL_BUNTE,
  URL_BUNTE_PUBLISH: import.meta.env.VITE_APP_URL_BUNTE_PUBLISH,
  URL_CHIP: import.meta.env.VITE_APP_URL_CHIP,
  URL_CHIP_PUBLISH: import.meta.env.VITE_APP_URL_CHIP_PUBLISH,
  URL_FOCUS: import.meta.env.VITE_APP_URL_FOCUS,
  URL_FOCUS_PUBLISH: import.meta.env.VITE_APP_URL_FOCUS_PUBLISH,
  CAT_FRONT_API: import.meta.env.VITE_APP_CAT_FRONT_API,
  CAT_FRONTEND_URL: import.meta.env.VITE_APP_CAT_FRONTEND_URL,
  KIM_URL: import.meta.env.VITE_APP_KIM_URL,
  NODE_ENV: import.meta.env.VITE_APP_NODE_ENV,
  VERSION: import.meta.env.VITE_APP_VERSION,
  CHIP_WIDGET_CONFIGURATOR_URL_BUNTE: import.meta.env.VITE_APP_CHIP_WIDGET_CONFIGURATOR_URL_BUNTE,
  CHIP_WIDGET_CONFIGURATOR_URL_FOCUS: import.meta.env.VITE_APP_CHIP_WIDGET_CONFIGURATOR_URL_FOCUS,
  CHIP_WIDGET_CONFIGURATOR_URL_CHIP: import.meta.env.VITE_APP_CHIP_WIDGET_CONFIGURATOR_URL_CHIP,
  CHIP_WIDGET_CONFIGURATOR_API_SECRET: import.meta.env.VITE_APP_API_SECRET,
};
