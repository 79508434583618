/* global GLOBAL_CONFIG */
import { connectSockets, createVueI18n, registerQuasar, setDefaultRouter, useModuleStore } from '@editor/ui-utils';
import { QueryClient, VueQueryPlugin } from '@tanstack/vue-query';
import deepmerge from 'deepmerge';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createApp } from 'vue';

import App from './app.vue';
import { modules } from './moduleLoader.js';
import router from './router.js';

const moduleStore = useModuleStore();
let messages = {};
for (const module of modules) {
  moduleStore.addModule(module);
  messages = deepmerge(messages, module.messages);
  for (const route of module.routes) {
    router.addRoute(route);
  }
}

setDefaultRouter(router);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(router);
app.use(pinia);
app.use(createVueI18n(messages));
app.use(VueQueryPlugin, { queryClient });
registerQuasar(app);
connectSockets(GLOBAL_CONFIG.API_HOST, { withCredentials: true }, false)
  .then(() => {
    return Promise.all(modules.map((m) => (m.init ? m.init(app) : Promise.resolve())));
  })
  .catch((e) => {
    console.error(e);
    process.exit(1);
  })
  // Call init once all modules are registered
  .finally(() => app.mount('#app'));
