/* eslint-disable */
export default {
  activity: 'M22 12h-4l-3 9L9 3l-3 9H2@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  airplay:
    'M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&m12 15 5 6H7l5-6z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'alert-circle':
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20zm0 6v4m0 4h.01@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'alert-octagon':
    'M7.86 2h8.28L22 7.86v8.28L16.14 22H7.86L2 16.14V7.86L7.86 2zM12 8v4m0 4h.01@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'alert-triangle':
    'M10.29 3.86 1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0zM12 9v4m0 4h.01@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'align-center': 'M18 10H6m15-4H3m18 8H3m15 4H6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'align-justify': 'M21 10H3m18-4H3m18 8H3m18 4H3@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'align-left': 'M17 10H3m18-4H3m18 8H3m14 4H3@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'align-right': 'M21 10H7m14-4H3m18 8H3m18 4H7@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  anchor:
    'M12 2a3 3 0 1 0 0 6 3 3 0 1 0 0-6zm0 20V8m-7 4H2a10 10 0 0 0 20 0h-3@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  aperture:
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20zm2.31 6 5.74 9.94M9.69 8h11.48M7.38 12l5.74-9.94M9.69 16 3.95 6.06M14.31 16H2.83m13.79-4-5.74 9.94@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  archive: 'M21 8v13H3V8M1 3h22v5H1zm9 9h4@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'arrow-down-circle':
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&m8 12 4 4 4-4m-4-4v8@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'arrow-down-left': 'M17 7 7 17m10 0H7V7@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'arrow-down-right': 'm7 7 10 10m0-10v10H7@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'arrow-down': 'M12 5v14m7-7-7 7-7-7@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'arrow-left-circle':
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&m12 8-4 4 4 4m4-4H8@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'arrow-left': 'M19 12H5m7 7-7-7 7-7@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'arrow-right-circle':
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&m12 16 4-4-4-4m-4 4h8@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'arrow-right': 'M5 12h14m-7-7 7 7-7 7@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'arrow-up-circle':
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&m16 12-4-4-4 4m4 4V8@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'arrow-up-left': 'M17 17 7 7m0 10V7h10@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'arrow-up-right': 'M7 17 17 7M7 7h10v10@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'arrow-up': 'M12 19V5m-7 7 7-7 7 7@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'at-sign':
    'M12 8a4 4 0 1 0 0 8 4 4 0 1 0 0-8z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  award:
    'M12 1a7 7 0 1 0 0 14 7 7 0 1 0 0-14z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M8.21 13.89 7 23l5-3 5 3-1.21-9.12@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'bar-chart-2': 'M18 20V10m-6 10V4M6 20v-6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'bar-chart': 'M12 20V10m6 10V4M6 20v-4@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'battery-charging':
    'M5 18H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h3.19M15 6h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-3.19M23 13v-2M11 6l-4 6h6l-4 6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  battery: 'M1 6h18v12H1zm22 7v-2@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'bell-off':
    'M13.73 21a2 2 0 0 1-3.46 0m8.36-8A17.89 17.89 0 0 1 18 8M6.26 6.26A5.86 5.86 0 0 0 6 8c0 7-3 9-3 9h14m1-9a6 6 0 0 0-9.33-5M1 1l22 22@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  bell: 'M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9m-4.27 13a2 2 0 0 1-3.46 0@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  bluetooth:
    'm6.5 6.5 11 11L12 23V1l5.5 5.5-11 11@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  bold: 'M6 4h8a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6zm0 8h9a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'book-open':
    'M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2zm20 0h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  book: 'M4 19.5A2.5 2.5 0 0 1 6.5 17H20@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  bookmark:
    'm19 21-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  box: 'M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M3.27 6.96 12 12.01l8.73-5.05M12 22.08V12@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  briefcase:
    'M2 7h20v14H2z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  calendar: 'M3 4h18v18H3zm13-2v4M8 2v4m-5 4h18@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'camera-off':
    'm1 1 22 22m-2-2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h3m3-3h6l2 3h4a2 2 0 0 1 2 2v9.34m-7.72-2.06a4 4 0 1 1-5.56-5.56@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  camera:
    'M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M12 9a4 4 0 1 0 0 8 4 4 0 1 0 0-8z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  cast: 'M2 16.1A5 5 0 0 1 5.9 20M2 12.05A9 9 0 0 1 9.95 20M2 8V6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-6M2 20h.01@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'check-circle':
    'M22 11.08V12a10 10 0 1 1-5.93-9.14@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M22 4 12 14.01l-3-3@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'check-square':
    'm9 11 3 3L22 4@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  check: 'M20 6 9 17l-5-5@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'chevron-down': 'm6 9 6 6 6-6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'chevron-left': 'm15 18-6-6 6-6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'chevron-right': 'm9 18 6-6-6-6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'chevron-up': 'm18 15-6-6-6 6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'chevrons-down': 'm7 13 5 5 5-5M7 6l5 5 5-5@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'chevrons-left': 'm11 17-5-5 5-5m7 10-5-5 5-5@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'chevrons-right': 'm13 17 5-5-5-5M6 17l5-5-5-5@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'chevrons-up': 'm17 11-5-5-5 5m10 7-5-5-5 5@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  chrome:
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M12 8a4 4 0 1 0 0 8 4 4 0 1 0 0-8zm9.17 0H12M3.95 6.06 8.54 14m2.34 7.94L15.46 14@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  circle:
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  clipboard:
    'M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M8 2h8v4H8z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  clock:
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M12 6v6l4 2@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'cloud-drizzle':
    'M8 19v2m0-8v2m8 4v2m0-8v2m-4 6v2m0-8v2m8-.42A5 5 0 0 0 18 7h-1.26A8 8 0 1 0 4 15.25@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'cloud-lightning':
    'M19 16.9A5 5 0 0 0 18 7h-1.26a8 8 0 1 0-11.62 9@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&m13 11-4 6h6l-4 6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'cloud-off':
    'M22.61 16.95A5 5 0 0 0 18 10h-1.26a8 8 0 0 0-7.05-6M5 5a8 8 0 0 0 4 15h9a5 5 0 0 0 1.7-.3M1 1l22 22@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'cloud-rain':
    'M16 13v8m-8-8v8m4-6v8m8-6.42A5 5 0 0 0 18 7h-1.26A8 8 0 1 0 4 15.25@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'cloud-snow':
    'M20 17.58A5 5 0 0 0 18 8h-1.26A8 8 0 1 0 4 16.25M8 16h.01M8 20h.01M12 18h.01M12 22h.01M16 16h.01M16 20h.01@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  cloud:
    'M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 0 0 0-10z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  code: 'm16 18 6-6-6-6M8 6l-6 6 6 6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  codepen:
    'm12 2 10 6.5v7L12 22 2 15.5v-7L12 2zm0 20v-6.5@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&m22 8.5-10 7-10-7@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&m2 15.5 10-7 10 7M12 2v6.5@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  codesandbox:
    'M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&m7.5 4.21 4.5 2.6 4.5-2.6m-9 15.58V14.6L3 12m18 0-4.5 2.6v5.19M3.27 6.96 12 12.01l8.73-5.05M12 22.08V12@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  coffee:
    'M18 8h1a4 4 0 0 1 0 8h-1M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8zm4-7v3m4-3v3m4-3v3@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  columns:
    'M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  command:
    'M18 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3H6a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3V6a3 3 0 0 0-3-3 3 3 0 0 0-3 3 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 3 3 0 0 0-3-3z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  compass:
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&m16.24 7.76-2.12 6.36-6.36 2.12 2.12-6.36 6.36-2.12z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  copy: 'M9 9h13v13H9z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'corner-down-left':
    'm9 10-5 5 5 5@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M20 4v7a4 4 0 0 1-4 4H4@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'corner-down-right':
    'm15 10 5 5-5 5@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M4 4v7a4 4 0 0 0 4 4h12@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'corner-left-down':
    'm14 15-5 5-5-5@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M20 4h-7a4 4 0 0 0-4 4v12@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'corner-left-up':
    'M14 9 9 4 4 9@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M20 20h-7a4 4 0 0 1-4-4V4@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'corner-right-down':
    'm10 15 5 5 5-5@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M4 4h7a4 4 0 0 1 4 4v12@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'corner-right-up':
    'm10 9 5-5 5 5@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M4 20h7a4 4 0 0 0 4-4V4@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'corner-up-left':
    'M9 14 4 9l5-5@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M20 20v-7a4 4 0 0 0-4-4H4@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'corner-up-right':
    'm15 14 5-5-5-5@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M4 20v-7a4 4 0 0 1 4-4h12@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  cpu: 'M4 4h16v16H4z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M9 9h6v6H9zm0-8v3m6-3v3M9 20v3m6-3v3m5-14h3m-3 5h3M1 9h3m-3 5h3@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'credit-card': 'M1 4h22v16H1zm0 6h22@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  crop: 'M6.13 1 6 16a2 2 0 0 0 2 2h15@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M1 6.13 16 6a2 2 0 0 1 2 2v15@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  crosshair:
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20zm10 10h-4M6 12H2m10-6V2m0 20v-4@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  database:
    'M12 2a9 3 0 1 0 0 6 9 3 0 1 0 0-6zm9 10c0 1.66-4 3-9 3s-9-1.34-9-3@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  delete:
    'M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm-3 5-6 6m0-6 6 6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  disc: 'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M12 9a3 3 0 1 0 0 6 3 3 0 1 0 0-6z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'divide-circle':
    'M8 12h8m-4 4h0m0-8h0m0-6a10 10 0 1 0 0 20 10 10 0 1 0 0-20z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'divide-square':
    'M3 3h18v18H3zm5 9h8m-4 4h0m0-8h0@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  divide:
    'M12 4a2 2 0 1 0 0 4 2 2 0 1 0 0-4zm-7 8h14m-7 4a2 2 0 1 0 0 4 2 2 0 1 0 0-4z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'dollar-sign':
    'M12 1v22m5-18H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'download-cloud':
    'm8 17 4 4 4-4m-4-5v9@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  download:
    'M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4m4-5 5 5 5-5m-5 5V3@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  dribbble:
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M8.56 2.75c4.37 6.03 6.02 9.42 8.03 17.72m2.54-15.38c-3.72 4.35-8.94 5.66-16.88 5.85m19.5 1.9c-3.5-.93-6.63-.82-8.94 0-2.58.92-5.01 2.86-7.44 6.32@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  droplet: 'm12 2.69 5.66 5.66a8 8 0 1 1-11.31 0z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'edit-2':
    'M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'edit-3':
    'M12 20h9M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  edit: 'M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'external-link':
    'M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6v6m-11 5L21 3@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'eye-off':
    'M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24M1 1l22 22@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  eye: 'M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M12 9a3 3 0 1 0 0 6 3 3 0 1 0 0-6z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  facebook:
    'M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'fast-forward':
    'm13 19 9-7-9-7v14zM2 19l9-7-9-7v14z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  feather:
    'M20.24 12.24a6 6 0 0 0-8.49-8.49L5 10.5V19h8.5zM16 8 2 22m15.5-7H9@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  figma:
    'M5 5.5A3.5 3.5 0 0 1 8.5 2H12v7H8.5A3.5 3.5 0 0 1 5 5.5zM12 2h3.5a3.5 3.5 0 1 1 0 7H12V2z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M12 12.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 1 1-7 0zm-7 7A3.5 3.5 0 0 1 8.5 16H12v3.5a3.5 3.5 0 1 1-7 0zm0-7A3.5 3.5 0 0 1 8.5 9H12v7H8.5A3.5 3.5 0 0 1 5 12.5z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'file-minus':
    'M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M14 2v6h6M9 15h6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'file-plus':
    'M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M14 2v6h6m-8 10v-6m-3 3h6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'file-text':
    'M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M14 2v6h6m-4 5H8m8 4H8m2-8H8@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  file: 'M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M13 2v7h7@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  film: 'M2 2h20v20H2zm5 0v20M17 2v20M2 12h20M2 7h5M2 17h5m10 0h5M17 7h5@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  filter: 'M22 3H2l8 9.46V19l4 2v-8.54L22 3z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  flag: 'M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1zm0 7v-7@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'folder-minus':
    'M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2zM9 14h6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'folder-plus':
    'M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2zm-10-8v6m-3-3h6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  folder:
    'M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  framer:
    'M5 16V9h14V2H5l14 14h-7m-7 0 7 7v-7m-7 0h7@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  frown:
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M16 16s-1.5-2-4-2-4 2-4 2m1-7h.01M15 9h.01@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  gift: 'M20 12v10H4V12M2 7h20v5H2zm10 15V7m0 0H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7zm0 0h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'git-branch':
    'M6 3v12M18 3a3 3 0 1 0 0 6 3 3 0 1 0 0-6zM6 15a3 3 0 1 0 0 6 3 3 0 1 0 0-6z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M18 9a9 9 0 0 1-9 9@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'git-commit':
    'M12 8a4 4 0 1 0 0 8 4 4 0 1 0 0-8zM1.05 12H7m10.01 0h5.95@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'git-merge':
    'M18 15a3 3 0 1 0 0 6 3 3 0 1 0 0-6zM6 3a3 3 0 1 0 0 6 3 3 0 1 0 0-6z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M6 21V9a9 9 0 0 0 9 9@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'git-pull-request':
    'M18 15a3 3 0 1 0 0 6 3 3 0 1 0 0-6zM6 3a3 3 0 1 0 0 6 3 3 0 1 0 0-6zm7 3h3a2 2 0 0 1 2 2v7M6 9v12@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  github:
    'M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  gitlab:
    'M22.65 14.39 12 22.13 1.35 14.39a.84.84 0 0 1-.3-.94l1.22-3.78 2.44-7.51A.42.42 0 0 1 4.82 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.49h8.1l2.44-7.51A.42.42 0 0 1 18.6 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.51L23 13.45a.84.84 0 0 1-.35.94z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  globe:
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M2 12h20M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  grid: 'M3 3h7v7H3zm11 0h7v7h-7zm0 11h7v7h-7zM3 14h7v7H3z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'hard-drive':
    'M22 12H2m3.45-6.89L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11zM6 16h.01M10 16h.01@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  hash: 'M4 9h16M4 15h16M10 3 8 21m8-18-2 18@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  headphones:
    'M3 18v-6a9 9 0 0 1 18 0v6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  heart:
    'M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'help-circle':
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3m.08 4h.01@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  hexagon:
    'M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  home: 'm3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M9 22V12h6v10@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  image:
    'M3 3h18v18H3z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M8.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 1 0 0-3zM21 15l-5-5L5 21@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  inbox:
    'M22 12h-6l-2 3h-4l-2-3H2@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M5.45 5.11 2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  info: 'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20zm0 14v-4m0-4h.01@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  instagram:
    'M2 2h20v20H2z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37zm1.5-4.87h.01@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  italic: 'M19 4h-9m4 16H5M15 4 9 20@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  key: 'm21 2-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0 3 3L22 7l-3-3m-3.5 3.5L19 4@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  layers:
    'M12 2 2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  layout: 'M3 3h18v18H3zm0 6h18M9 21V9@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'life-buoy':
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M12 8a4 4 0 1 0 0 8 4 4 0 1 0 0-8zM4.93 4.93l4.24 4.24m5.66 5.66 4.24 4.24m-4.24-9.9 4.24-4.24m-4.24 4.24 3.53-3.53M4.93 19.07l4.24-4.24@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'link-2':
    'M15 7h3a5 5 0 0 1 5 5 5 5 0 0 1-5 5h-3m-6 0H6a5 5 0 0 1-5-5 5 5 0 0 1 5-5h3m-1 5h8@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  link: 'M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  linkedin:
    'M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6zM2 9h4v12H2zm2-7a2 2 0 1 0 0 4 2 2 0 1 0 0-4z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  list: 'M8 6h13M8 12h13M8 18h13M3 6h.01M3 12h.01M3 18h.01@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  loader:
    'M12 2v4m0 12v4M4.93 4.93l2.83 2.83m8.48 8.48 2.83 2.83M2 12h4m12 0h4M4.93 19.07l2.83-2.83m8.48-8.48 2.83-2.83@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  lock: 'M3 11h18v11H3zm4 0V7a5 5 0 0 1 10 0v4@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'log-in':
    'M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4m-5-4 5-5-5-5m5 5H3@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'log-out':
    'M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4m7 14 5-5-5-5m5 5H9@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  mail: 'M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&m22 6-10 7L2 6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'map-pin':
    'M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M12 7a3 3 0 1 0 0 6 3 3 0 1 0 0-6z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  map: 'M1 6v16l7-4 8 4 7-4V2l-7 4-8-4-7 4zm7-4v16m8-12v16@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'maximize-2':
    'M15 3h6v6M9 21H3v-6M21 3l-7 7M3 21l7-7@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  maximize:
    'M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  meh: 'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20zM8 15h8M9 9h.01M15 9h.01@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  menu: 'M3 12h18M3 6h18M3 18h18@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'message-circle':
    'M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'message-square':
    'M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'mic-off':
    'm1 1 22 22M9 9v3a3 3 0 0 0 5.12 2.12M15 9.34V4a3 3 0 0 0-5.94-.6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M17 16.95A7 7 0 0 1 5 12v-2m14 0v2a7 7 0 0 1-.11 1.23M12 19v4m-4 0h8@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  mic: 'M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M19 10v2a7 7 0 0 1-14 0v-2m7 9v4m-4 0h8@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'minimize-2':
    'M4 14h6v6m10-10h-6V4m0 6 7-7M3 21l7-7@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  minimize:
    'M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'minus-circle':
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20zM8 12h8@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'minus-square': 'M3 3h18v18H3zm5 9h8@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  minus: 'M5 12h14@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  monitor: 'M2 3h20v14H2zm6 18h8m-4-4v4@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  moon: 'M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'more-horizontal':
    'M12 11a1 1 0 1 0 0 2 1 1 0 1 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 1 0 0-2zM5 11a1 1 0 1 0 0 2 1 1 0 1 0 0-2z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'more-vertical':
    'M12 11a1 1 0 1 0 0 2 1 1 0 1 0 0-2zm0-7a1 1 0 1 0 0 2 1 1 0 1 0 0-2zm0 14a1 1 0 1 0 0 2 1 1 0 1 0 0-2z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'mouse-pointer':
    'm3 3 7.07 16.97 2.51-7.39 7.39-2.51L3 3zm10 10 6 6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  move: 'm5 9-3 3 3 3M9 5l3-3 3 3m0 14-3 3-3-3M19 9l3 3-3 3M2 12h20M12 2v20@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  music:
    'M9 18V5l12-2v13@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M6 15a3 3 0 1 0 0 6 3 3 0 1 0 0-6zm12-2a3 3 0 1 0 0 6 3 3 0 1 0 0-6z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'navigation-2': 'm12 2 7 19-7-4-7 4 7-19z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  navigation: 'm3 11 19-9-9 19-2-8-8-2z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  octagon:
    'M7.86 2h8.28L22 7.86v8.28L16.14 22H7.86L2 16.14V7.86L7.86 2z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  package:
    'm16.5 9.4-9-5.19M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M3.27 6.96 12 12.01l8.73-5.05M12 22.08V12@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  paperclip:
    'm21.44 11.05-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'pause-circle':
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20zm-2 13V9m4 6V9@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  pause: 'M6 4h4v16H6zm8 0h4v16h-4z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'pen-tool':
    'm12 19 7-7 3 3-7 7-3-3z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&m18 13-1.5-7.5L2 2l3.5 14.5L13 18l5-5zM2 2l7.586 7.586@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M11 9a2 2 0 1 0 0 4 2 2 0 1 0 0-4z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  percent:
    'M19 5 5 19M6.5 4a2.5 2.5 0 1 0 0 5 2.5 2.5 0 1 0 0-5zm11 11a2.5 2.5 0 1 0 0 5 2.5 2.5 0 1 0 0-5z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'phone-call':
    'M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'phone-forwarded':
    'm19 1 4 4-4 4m-4-4h8m-1 11.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'phone-incoming':
    'M16 2v6h6m1-7-7 7m6 8.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'phone-missed':
    'm23 1-6 6m0-6 6 6m-1 9.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'phone-off':
    'M10.68 13.31a16 16 0 0 0 3.41 2.6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7 2 2 0 0 1 1.72 2v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.42 19.42 0 0 1-3.33-2.67m-2.67-3.34a19.79 19.79 0 0 1-3.07-8.63A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91M23 1 1 23@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'phone-outgoing':
    'M23 7V1h-6m-1 7 7-7m-1 15.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  phone:
    'M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'pie-chart':
    'M21.21 15.89A10 10 0 1 1 8 2.83@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M22 12A10 10 0 0 0 12 2v10z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'play-circle':
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&m10 8 6 4-6 4V8z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  play: 'm5 3 14 9-14 9V3z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'plus-circle':
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20zm0 6v8m-4-4h8@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'plus-square': 'M3 3h18v18H3zm9 5v8m-4-4h8@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  plus: 'M12 5v14m-7-7h14@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  pocket:
    'M4 3h16a2 2 0 0 1 2 2v6a10 10 0 0 1-10 10A10 10 0 0 1 2 11V5a2 2 0 0 1 2-2z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&m8 10 4 4 4-4@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  power: 'M18.36 6.64a9 9 0 1 1-12.73 0M12 2v10@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  printer:
    'M6 9V2h12v7M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M6 14h12v8H6z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  radio:
    'M12 10a2 2 0 1 0 0 4 2 2 0 1 0 0-4zm4.24-2.24a6 6 0 0 1 0 8.49m-8.48-.01a6 6 0 0 1 0-8.49m11.31-2.82a10 10 0 0 1 0 14.14m-14.14 0a10 10 0 0 1 0-14.14@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'refresh-ccw':
    'M1 4v6h6m16 10v-6h-6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4-4.64 4.36A9 9 0 0 1 3.51 15@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'refresh-cw':
    'M23 4v6h-6M1 20v-6h6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  repeat:
    'm17 1 4 4-4 4@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M3 11V9a4 4 0 0 1 4-4h14M7 23l-4-4 4-4@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M21 13v2a4 4 0 0 1-4 4H3@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  rewind: 'm11 19-9-7 9-7v14zm11 0-9-7 9-7v14z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'rotate-ccw':
    'M1 4v6h6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M3.51 15a9 9 0 1 0 2.13-9.36L1 10@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'rotate-cw':
    'M23 4v6h-6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M20.49 15a9 9 0 1 1-2.12-9.36L23 10@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  rss: 'M4 11a9 9 0 0 1 9 9M4 4a16 16 0 0 1 16 16M5 18a1 1 0 1 0 0 2 1 1 0 1 0 0-2z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  save: 'M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M17 21v-8H7v8M7 3v5h8@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  scissors:
    'M6 3a3 3 0 1 0 0 6 3 3 0 1 0 0-6zm0 12a3 3 0 1 0 0 6 3 3 0 1 0 0-6zM20 4 8.12 15.88m6.35-1.4L20 20M8.12 8.12 12 12@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  search:
    'M11 3a8 8 0 1 0 0 16 8 8 0 1 0 0-16zm10 18-4.35-4.35@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  send: 'M22 2 11 13M22 2l-7 20-4-9-9-4 20-7z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  server:
    'M2 2h20v8H2zm0 12h20v8H2zm4-8h.01M6 18h.01@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  settings:
    'M12 9a3 3 0 1 0 0 6 3 3 0 1 0 0-6z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'share-2':
    'M18 2a3 3 0 1 0 0 6 3 3 0 1 0 0-6zM6 9a3 3 0 1 0 0 6 3 3 0 1 0 0-6zm12 7a3 3 0 1 0 0 6 3 3 0 1 0 0-6zm-9.41-2.49 6.83 3.98m-.01-10.98-6.82 3.98@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  share:
    'M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8m-4-6-4-4-4 4m4-4v13@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'shield-off':
    'M19.69 14a6.9 6.9 0 0 0 .31-2V5l-8-3-3.16 1.18M4.73 4.73 4 5v7c0 6 8 10 8 10a20.29 20.29 0 0 0 5.62-4.38M1 1l22 22@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  shield:
    'M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'shopping-bag':
    'M6 2 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4zM3 6h18@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M16 10a4 4 0 0 1-8 0@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'shopping-cart':
    'M9 20a1 1 0 1 0 0 2 1 1 0 1 0 0-2zm11 0a1 1 0 1 0 0 2 1 1 0 1 0 0-2zM1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  shuffle:
    'M16 3h5v5M4 20 21 3m0 13v5h-5m-1-6 6 6M4 4l5 5@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  sidebar: 'M3 3h18v18H3zm6 0v18@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'skip-back': 'M19 20 9 12l10-8v16zM5 19V5@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'skip-forward': 'm5 4 10 8-10 8V4zm14 1v14@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  slack:
    'M14.5 10c-.83 0-1.5-.67-1.5-1.5v-5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5zm6 0H19V8.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm-11 4c.83 0 1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5S8 21.33 8 20.5v-5c0-.83.67-1.5 1.5-1.5zm-6 0H5v1.5c0 .83-.67 1.5-1.5 1.5S2 16.33 2 15.5 2.67 14 3.5 14zm10.5.5c0-.83.67-1.5 1.5-1.5h5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5c-.83 0-1.5-.67-1.5-1.5zm1.5 4.5H14v1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zM10 9.5C10 8.67 9.33 8 8.5 8h-5C2.67 8 2 8.67 2 9.5S2.67 11 3.5 11h5c.83 0 1.5-.67 1.5-1.5zM8.5 5H10V3.5C10 2.67 9.33 2 8.5 2S7 2.67 7 3.5 7.67 5 8.5 5z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  slash:
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20zM4.93 4.93l14.14 14.14@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  sliders:
    'M4 21v-7m0-4V3m8 18v-9m0-4V3m8 18v-5m0-4V3M1 14h6m2-6h6m2 8h6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  smartphone: 'M5 2h14v20H5zm7 16h.01@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  smile:
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M8 14s1.5 2 4 2 4-2 4-2M9 9h.01M15 9h.01@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  speaker:
    'M4 2h16v20H4z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M12 10a4 4 0 1 0 0 8 4 4 0 1 0 0-8zm0-4h.01@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  square: 'M3 3h18v18H3z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  star: 'm12 2 3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'stop-circle':
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M9 9h6v6H9z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  sun: 'M12 7a5 5 0 1 0 0 10 5 5 0 1 0 0-10zm0-6v2m0 18v2M4.22 4.22l1.42 1.42m12.72 12.72 1.42 1.42M1 12h2m18 0h2M4.22 19.78l1.42-1.42M18.36 5.64l1.42-1.42@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  sunrise:
    'M17 18a5 5 0 0 0-10 0m5-16v7m-7.78 1.22 1.42 1.42M1 18h2m18 0h2m-4.64-6.36 1.42-1.42M23 22H1M8 6l4-4 4 4@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  sunset:
    'M17 18a5 5 0 0 0-10 0m5-9V2m-7.78 8.22 1.42 1.42M1 18h2m18 0h2m-4.64-6.36 1.42-1.42M23 22H1M16 5l-4 4-4-4@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  tablet: 'M4 2h16v20H4zm8 16h.01@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  tag: 'm20.59 13.41-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82zM7 7h.01@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  target:
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M12 6a6 6 0 1 0 0 12 6 6 0 1 0 0-12z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M12 10a2 2 0 1 0 0 4 2 2 0 1 0 0-4z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  terminal: 'm4 17 6-6-6-6m8 14h8@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  thermometer:
    'M14 14.76V3.5a2.5 2.5 0 0 0-5 0v11.26a4.5 4.5 0 1 0 5 0z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'thumbs-down':
    'M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'thumbs-up':
    'M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'toggle-left':
    'M1 5h22v14H1z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M8 9a3 3 0 1 0 0 6 3 3 0 1 0 0-6z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'toggle-right':
    'M1 5h22v14H1z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M16 9a3 3 0 1 0 0 6 3 3 0 1 0 0-6z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  tool: 'M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'trash-2':
    'M3 6h18m-2 0v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m-6 5v6m4-6v6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  trash:
    'M3 6h18m-2 0v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  trello:
    'M3 3h18v18H3z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M7 7h3v9H7zm7 0h3v5h-3z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'trending-down':
    'm23 18-9.5-9.5-5 5L1 6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M17 18h6v-6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'trending-up':
    'm23 6-9.5 9.5-5-5L1 18@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M17 6h6v6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  triangle:
    'M10.29 3.86 1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  truck:
    'M1 3h15v13H1zm15 5h4l3 3v5h-7V8zM5.5 16a2.5 2.5 0 1 0 0 5 2.5 2.5 0 1 0 0-5zm13 0a2.5 2.5 0 1 0 0 5 2.5 2.5 0 1 0 0-5z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  tv: 'M2 7h20v15H2zm15-5-5 5-5-5@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  twitch:
    'M21 2H3v16h5v4l4-4h5l4-4V2zm-10 9V7m5 4V7@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  twitter:
    'M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  type: 'M4 7V4h16v3M9 20h6M12 4v16@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  umbrella:
    'M23 12a11.05 11.05 0 0 0-22 0zm-5 7a3 3 0 0 1-6 0v-7@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  underline:
    'M6 3v7a6 6 0 0 0 6 6 6 6 0 0 0 6-6V3M4 21h16@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  unlock: 'M3 11h18v11H3zm4 0V7a5 5 0 0 1 9.9-1@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'upload-cloud':
    'm16 16-4-4-4 4m4-4v9@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&m16 16-4-4-4 4@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  upload:
    'M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4m14-7-5-5-5 5m5-5v12@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'user-check':
    'M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2M8.5 3a4 4 0 1 0 0 8 4 4 0 1 0 0-8zm8.5 8 2 2 4-4@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'user-minus':
    'M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2M8.5 3a4 4 0 1 0 0 8 4 4 0 1 0 0-8zM23 11h-6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'user-plus':
    'M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2M8.5 3a4 4 0 1 0 0 8 4 4 0 1 0 0-8zM20 8v6m3-3h-6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'user-x':
    'M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2M8.5 3a4 4 0 1 0 0 8 4 4 0 1 0 0-8zM18 8l5 5m0-5-5 5@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  user: 'M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2m8-18a4 4 0 1 0 0 8 4 4 0 1 0 0-8z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  users:
    'M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2M9 3a4 4 0 1 0 0 8 4 4 0 1 0 0-8zm14 18v-2a4 4 0 0 0-3-3.87m-4-12a4 4 0 0 1 0 7.75@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'video-off':
    'M16 16v1a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h2m5.66 0H14a2 2 0 0 1 2 2v3.34l1 1L23 7v10M1 1l22 22@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  video: 'm23 7-7 5 7 5V7zM1 5h15v14H1z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  voicemail:
    'M5.5 7a4.5 4.5 0 1 0 0 9 4.5 4.5 0 1 0 0-9zm13 0a4.5 4.5 0 1 0 0 9 4.5 4.5 0 1 0 0-9zm-13 9h13@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'volume-1':
    'M11 5 6 9H2v6h4l5 4V5zm4.54 3.46a5 5 0 0 1 0 7.07@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'volume-2':
    'M11 5 6 9H2v6h4l5 4V5zm8.07-.07a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'volume-x':
    'M11 5 6 9H2v6h4l5 4V5zm12 4-6 6m0-6 6 6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  volume: 'M11 5 6 9H2v6h4l5 4V5z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  watch:
    'M12 5a7 7 0 1 0 0 14 7 7 0 1 0 0-14z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&M12 9v3l1.5 1.5m3.01 3.85-.35 3.83a2 2 0 0 1-2 1.82H9.83a2 2 0 0 1-2-1.82l-.35-3.83m.01-10.7.35-3.83A2 2 0 0 1 9.83 1h4.35a2 2 0 0 1 2 1.82l.35 3.83@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'wifi-off':
    'm1 1 22 22m-6.28-11.94A10.94 10.94 0 0 1 19 12.55m-14 0a10.94 10.94 0 0 1 5.17-2.39m.54-5.11A16 16 0 0 1 22.58 9M1.42 9a15.91 15.91 0 0 1 4.7-2.88m2.41 9.99a6 6 0 0 1 6.95 0M12 20h.01@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  wifi: 'M5 12.55a11 11 0 0 1 14.08 0M1.42 9a16 16 0 0 1 21.16 0M8.53 16.11a6 6 0 0 1 6.95 0M12 20h.01@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  wind: 'M9.59 4.59A2 2 0 1 1 11 8H2m10.59 11.41A2 2 0 1 0 14 16H2m15.73-8.27A2.5 2.5 0 1 1 19.5 12H2@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'x-circle':
    'M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20zm3 7-6 6m0-6 6 6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'x-octagon':
    'M7.86 2h8.28L22 7.86v8.28L16.14 22H7.86L2 16.14V7.86L7.86 2zM15 9l-6 6m0-6 6 6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'x-square': 'M3 3h18v18H3zm6 6 6 6m0-6-6 6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  x: 'M18 6 6 18M6 6l12 12@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  youtube:
    'M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2&&m9.75 15.02 5.75-3.27-5.75-3.27v6.54z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'zap-off':
    'M12.41 6.75 13 2l-2.43 2.92m8 7.99L21 10h-5.34M8 8l-5 6h9l-1 8 5-6M1 1l22 22@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  zap: 'M13 2 3 14h9l-1 8 10-12h-9l1-8z@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'zoom-in':
    'M11 3a8 8 0 1 0 0 16 8 8 0 1 0 0-16zm10 18-4.35-4.35M11 8v6m-3-3h6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
  'zoom-out':
    'M11 3a8 8 0 1 0 0 16 8 8 0 1 0 0-16zm10 18-4.35-4.35M8 11h6@@fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2',
} as Record<string, string>;
