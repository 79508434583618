import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  // route example
  // should be removed later
  { path: '', redirect: '/articles' },
  {
    path: '/data-check',
    name: 'data_check',
    component: () => import('@/components/data-integrity.view.vue'),
    meta: { requiredRight: 'data_check' },
  },
];

const router = createRouter({ history: createWebHistory(), routes });
export default router;
